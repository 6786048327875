@import "./config";
/* Ensure the table container has a fixed height with overflow enabled */
.table-responsive {
  max-height: 400px; /* Adjust based on your needs */
  overflow-y: auto; /* This makes the table scrollable */
  position: relative; /* Add relative positioning to the container */
  //   background-color: white;
  //   opacity: 1;
}
.table-header-bg {
  background-color: white;
}
/* Make the table header sticky at the top */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
// .sticky-header th {
//     position: sticky;
//     top: 0;
//     background-color: white;
//     z-index: 2;
//   }

.sticky-footer td {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: -1; /* Lower z-index to ensure it stays below the header */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for better visibility */
}

/* Optional: Make sure the cells have padding */
